import $ from 'jquery';
import React, { useEffect,useState } from 'react';

// Images Common File
import Img from '../Img';

import helpers from './../../utils';

function Bottombanner() {

    const [deskBottomImage, setDeskBottomImage] = useState(null);
    const [mobileBottomImage, setMobileBottomImage] = useState(null);
    useEffect(() => {
        const timer = setTimeout(() => {
            $(".sticky-banner-bx").slideDown();
            $(".banner-outer").removeClass("open");
        }, 5000);

        setDeskBottomImage(Img.demo_ad_img1);    
        setMobileBottomImage(Img.demo_ad_img5);     
        const fetchBottomBannerImage = async () => {          
            const deskBottomImageRes = await helpers.loadAdsWithToggleBottom(21,"cdfgd");
            if (deskBottomImageRes != '') {
                setDeskBottomImage(deskBottomImageRes.ad.image);
            } 

            const mobileBottomImageRes = await helpers.loadAdsWithToggleBottom(16,"cdfgd");
            if (mobileBottomImageRes != '') {
                setMobileBottomImage(mobileBottomImageRes.ad.image);
            }        
        };

        fetchBottomBannerImage();
        return () => {
          clearTimeout(timer);
        };
    }, []);    

    const handleocToggle = () => {
        if ($(".sticky-banner-bx").is(":hidden")) {
            $(".sticky-banner-bx").slideDown();
            $(".banner-outer").removeClass("open");
        } else if($(".sticky-banner-bx").is(":visible")){
            $(".sticky-banner-bx").slideUp();
            $(".banner-outer").addClass("open");
        }
    };

    
    return (
        <>
        <div className="banner-outer bb-outer open">
            <div className='sbc-btn' onClick={handleocToggle}>
                <i className="fa fa-angle-up"></i>
            </div>
            <div className="sticky-banner-bx" style={{ display: 'none' }}>
                <img className="desk-img" src={deskBottomImage} alt="" loading="lazy" />
                <img className="mobile-img" src={mobileBottomImage} alt="" loading="lazy" />
            </div>
        </div>
        </>
    );
}

export default Bottombanner;