import React, { useEffect , useState } from 'react';

// Css Link
import '../css/breakingnews.css'

import helpers from './../utils';

function Breakingnews() {

    const [brekingNews, setBrekingNews] = useState([]);

    useEffect(() => {
        const $ = window.$;
        // $(document).ready(function() {   
        //     $(".brealink-slider-bx").infiniteslide({ speed: 70, pauseonhover: false, clone: 2 });
        // });

        const getBrekingNews = async () => {                      
            const breking_news_response = await helpers.apiCall(helpers.API_BASE_URL+'get-breakings',{page:1,limit:5});            
            if (breking_news_response != '') {
                setBrekingNews(breking_news_response.news);
                $(".brealink-slider-bx").infiniteslide({ speed: 70, pauseonhover: false, clone: 2 });
            }
        };
        getBrekingNews();
        
    },[]);


    return (
        <>
        <div className="brealink-outer">
            <div className="breking-tag">બ્રેકિંગ</div>
            <div className="brealink-slider-bx">
                {brekingNews.length > 0 && (
                  brekingNews.map((breking_news,index) => (
                    <div key={index} >{breking_news.title}</div>
                  ))
                )}                
            </div>
        </div>
        </>
    );
}

export default Breakingnews;