import React, { useEffect,useState } from 'react';
import helpers from './../../utils';
// Images Common File
import Img from '../Img';

function Horizontalbanner(props) {

    const [deskImage, setDeskImage] = useState(null);
    const [mobileImage, setMobileImage] = useState(null);

    useEffect(() => {   
        const fetchBannerImage = async () => {          
            const deskImageRes = await helpers.loadAds(21);
            if (deskImageRes != '') {
                setDeskImage(deskImageRes.ad.image);
            } 

            const mobileImageRes = await helpers.loadAds(16);
            if (mobileImageRes != '') {
                setMobileImage(mobileImageRes.ad.image);
            }        
        };

        fetchBannerImage();      
    }, []);   

    return (
        <div className='bnr-da-img'>
            <img className="desk-img width-fit-content" src={deskImage} alt="" loading="lazy" />
            <img className="mobile-img" src={mobileImage} alt="" loading="lazy" />
        </div>
    );
}

export default Horizontalbanner;