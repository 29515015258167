import React, { useEffect,useState } from 'react';
import helpers from './../../utils';
// Images Common File
import Img from '../Img';

function Verticalbanner() {

    const [deskImage, setDeskImage] = useState(null);    

    useEffect(() => {   
        const fetchImage = async () => {          
            const deskImageRes = await helpers.loadAds(13);
            if (deskImageRes != '') {
                setDeskImage(deskImageRes.ad.image);
            }       
        };

        fetchImage();      
    }, []);

    return (
        <div className='bnr-da-img'>
            <img src={deskImage} alt="" loading="lazy" />
        </div>
    );
}

export default Verticalbanner;