import $ from 'jquery';
import 'jquery-validation';
import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import helpers from './../utils';

import { Modal } from 'react-bootstrap';

// Css Link
import '../css/sidemenu.css'

// Images Common File
import Img from '../Components/Img';

// Design File
import Socialicon from '../Components/Socialicon';
import Storelink from '../Components/Storelink';

function Sidemenu() {
    const navigate = useNavigate();
    const location = useLocation();

    const [errMsg, setErrMsg] = useState([]);
    const [successMsg, setSuccessMsg] = useState([]);

    const formRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageValue, setSelectedImageValue] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedImageValue(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        else
        {
            setSelectedImageValue(null);
            setSelectedImage(null);
        }
    };

    const handleClearImage = () => {
        // Clear the value by resetting the form or changing the key of the file input
        if (formRef.current) {
            formRef.current.selectfile.value = '';
        }
        setSelectedImage(null);
        setSelectedImageValue(null);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrMsg([]);
        setSuccessMsg([]);
        $.validator.addMethod("email_exp", function(value, element) {
            return this.optional(element) || (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i).test(value);
        }, "Please enter a valid number.");
        // Use jQuery validation on the form
        $(formRef.current).validate({
            rules: {
                feedback_email: {
                    required: true,
                    email: true,
                    email_exp: true,
                },
                feedback_description: {
                    required: true,
                },
            },
            messages: {
                feedback_email: {
                    required: 'Email is required',
                    email: 'Invalid email address',
                    email_exp: 'Invalid email address',
                },
                feedback_description: {
                    required: 'Message is required',
                },
            },
            submitHandler: async function (form) {
                // Your logic when the form is valid
                alert('Form submitted!');
                setErrMsg([]);
                setSuccessMsg([]);
                const formData = $(formRef.current).serializeArray();
                var form_data = [];
                if(Object.keys(formData).length > 0)
                {
                    formData.map((item,index) => (        
                        form_data[item.name] = item.value
                    ));     
                }
                if(selectedImageValue)
                {
                    form_data['feedback_file'] = selectedImageValue;
                }
                try {
                    const related_news_response = await helpers.apiCallWithErrorMessage(helpers.API_BASE_URL+'send-feedback',form_data);
                    if (related_news_response != '') {
                        if(related_news_response.error==0 || related_news_response.error == '0')
                        {
                            if (formRef.current) {                  
                                formRef.current.reset();
                                formRef.current.selectfile.value = '';
                                setSelectedImageValue(null);
                                setSelectedImage(null);
                            }
                            setTimeout(function () {
                                handleFeedbackClose();
                            }, 1000);
                            setSuccessMsg({'success' : related_news_response.msg});
                        }
                        else
                        {
                            setErrMsg({'error' : related_news_response.msg});
                        }
                    }
                    else
                    {
                        setErrMsg({'error' : 'Feedback not send'});
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            },
        });

        // Trigger the form validation
        $(formRef.current).valid();
    };

    useEffect(() => {},[errMsg]);
    useEffect(() => {
        if(Object.keys(successMsg).length > 0)
        {
            setTimeout(function () {
                setSuccessMsg([]);
            }, 10000);
        }
    }, [successMsg]);

    // Feedback Popup js
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const handleFeedbackClose = () => setShowFeedbackModal(false);
    const handleFeedbackShow = () => setShowFeedbackModal(true);


    useEffect(() => {
        $(".menu-icon-bx").on("click", function () {
            $(".side-menu-bx").addClass("active");
            $("body").addClass("over-body");
        });

        $(".trigger-closed, .overlaydiv, .smb-menu-bx .smb-link").on("click", function () {
            $(".side-menu-bx").removeClass("active");
            $("body").removeClass("over-body");
        });
    });
    return (
        <>
            <aside className="side-menu-bx">
                <div className="smb-logo-bx">
                    <span className="close-icon trigger-closed">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="38" d="M368 368L144 144M368 144L144 368"/></svg>
                    </span>
                    <a href="/" className="smb-logo-link"><img className="logo-img" src={Img.logo} alt="Morbi Update" /></a>
                </div>
                <div className="smb-menu-bx">
                    <ul>
                        <li><NavLink className="smb-link" exact="true" to ="/"><ion-icon name="home-outline"></ion-icon>Home</NavLink></li>
                        <li><div onClick={handleFeedbackShow} className="smb-link"><ion-icon name="information-circle-outline"></ion-icon>Feedback</div></li>
                        <li><NavLink className="smb-link" exact="true" to="/about-us"><ion-icon name="document-text-outline"></ion-icon>About Us</NavLink></li>
                        <li><NavLink className="smb-link" exact="true" to="/privacy-policy"><ion-icon name="document-text-outline"></ion-icon>Privacy policy</NavLink></li>
                        <li><NavLink className="smb-link" exact="true" to="/advertisement"><ion-icon name="bookmarks-outline"></ion-icon>Advertisement</NavLink></li>
                        <li><NavLink className="smb-link" exact="true" to="/contact-us"><ion-icon name="call-outline"></ion-icon>Contact Us</NavLink></li>
                    </ul>
                </div>
                <div className="smb-footer">
                    <Socialicon />
                    <Storelink />
                </div>

            </aside>
            <div className="overlaydiv"></div>


            <Modal className="CommonModal fullwhmodal feedbackmodal" centered show={showFeedbackModal} onHide={handleFeedbackClose}>
                <div className="commonmodal-bx">
                    <span className="common-close-icon" onClick={handleFeedbackClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="32"
                                d="M368 368L144 144M368 144L144 368"
                            />
                        </svg>
                    </span>
                    <div className="commonmodal-lable">Share your Feedback</div>
                    {
                        (Object.keys(errMsg).length > 0) && (
                            <div className="alert alert-danger" role="alert">
                                <h5 className="alert-heading">Error</h5>
                                <hr />
                                {
                                    Object.keys(errMsg).map((item:any,index:any) => (
                                        <p key={index} className="mb-0"  dangerouslySetInnerHTML={{ __html: errMsg[item] }}></p>
                                    ))  
                                }                                  
                            </div>
                        )
                    }
                    {
                        (Object.keys(successMsg).length > 0) && (
                            <div className="alert alert-success" role="alert">
                                <h5 className="alert-heading">Success</h5>
                                <hr />
                                {
                                    Object.keys(successMsg).map((item:any,index:any) => (
                                        <p key={index} className="mb-0"  dangerouslySetInnerHTML={{ __html: successMsg[item] }}></p>
                                    ))  
                                }                                  
                            </div>                                
                        )
                    }
                    <form ref={formRef} onSubmit={handleSubmit} className="feedback-form">
                        <div className="custom-form__group">
                            <input type="email" name="feedback_email" id="feedback_email" placeholder=" "/>
                            <label className="group__label">Email Id</label>
                        </div>
                        <div className="custom-form__group">
                            <textarea name="feedback_description" id="feedback_description" rows="3" placeholder=" "></textarea>
                            <label className="group__label">Describe your issue or share your ideas</label>
                        </div>
                        <div className="custom-form__group">
                            <input type="file" id="selectfile" name="selectfile" onChange={handleImageChange} className="d-none" accept="image/*" />
                            <label htmlFor="selectfile" className="choosefile_label">
                                <div className="selectfilebtn"><i className="fa fa-cloud-upload" aria-hidden="true"></i>Attach screenshot</div>
                            </label>
                            {selectedImage && (
                            <div className="selectedfile">
                                <img src={selectedImage} alt="Morbi Update" />
                                <div className="selectedfilbtn-bx">
                                    <label htmlFor="selectfile" className="sfb-icon edit-icon"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>Edit</label>
                                    <div className="sfb-icon delete-icon" onClick={handleClearImage} ><i className="fa fa-trash-o" aria-hidden="true"></i>Delete</div>
                                </div>
                            </div>
                            )}
                        </div>
                        <button type="submit" className="submit-btn" >Submit <i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                    </form>
                </div>
            </Modal>
        </>
    );
}

export default Sidemenu;



