import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import helpers from './../../utils';

// Images Common File
import Img from '../Img';

function Trendingimgwidgets() {

    const [data, setData] = useState([]);    

    useEffect(() => {   
        const fetchData = async () => {          
            const dataRes = await helpers.loadTrendingNews(5);
            if (dataRes != '') {
                setData(dataRes.news);
            }       
        };
        fetchData();      
    }, []);

    useEffect(() => {},[data]);

    return (
        <div className="cat-news-list trendingimg-cnl"> 
            <div className='common-heading'>Trending News</div>
            {Object.keys(data).length > 0 && (
                data.map((item,index) => (
                    <div className="cnl-item" key={index}>
                        <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                            <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                        </Link>
                        <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                            <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                        </Link>
                    </div>
                ))
            )}
        </div>
    );
}

export default Trendingimgwidgets;