import React, { useEffect , useState } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import helpers from './../utils';

// Css Link
import '../css/featuredslider.css';

// Images Common File
import Img from '../Components/Img';

//
import Squarebanner from "../Components/Adsection/Squarebanner"

function Featuredslider() {
    const [featuredSliderData, setFeaturedSliderData] = useState([]);
    const featuredslider = {
        loop: true,
        autoplayTimeout:5000,
        smartSpeed: 1000,
        autoplayHoverPause: true,
        dots:true,
        nav:true,
        autoplay: true,
        items: 1,
        navText: [
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" d="M328 112L184 256l144 144"/></svg>',
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" d="M184 112l144 144-144 144"/></svg>'],
        responsiveClass: true,
        responsive: {
          0: {
              dots:false,
          },
          769: {
              dots:false,
          }
        }
    };

    useEffect(() => {
        const getFeaturedslider = async () => {                      
            const featured_slider_response = await helpers.apiCall(helpers.API_BASE_URL+'home_slider',{});  
            if (featured_slider_response != '') {
                setFeaturedSliderData(featured_slider_response.data);
                const featuredslider = {
                    loop: true,
                    autoplayTimeout:5000,
                    smartSpeed: 1000,
                    autoplayHoverPause: true,
                    dots:true,
                    nav:true,
                    autoplay: true,
                    items: 1,
                    navText: [
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" d="M328 112L184 256l144 144"/></svg>',
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" d="M184 112l144 144-144 144"/></svg>'],
                    responsiveClass: true,
                    responsive: {
                      0: {
                          dots:false,
                      },
                      769: {
                          dots:false,
                      }
                    }
                };
            }
        };
        getFeaturedslider();
    },[]);

    useEffect(() => {},[featuredSliderData]);

    return (
        <>
            {featuredSliderData.length > 0 && (
                <div className='custom-container'>
                    <div className='featured-main-bx'>
                        <div className='featured-slider owl-hover'>
                            <OwlCarousel {...featuredslider}>
                                {featuredSliderData.map((item,index) => (
                                    <Link to={((item.link_url2) ? item.link_url2 : 'javascript:void(0);' )} key={index} className="featured-item">
                                        <div className="featured-item-link">
                                            <img className="cmn-img-bg" src={item.image} alt="" loading="lazy" />
                                        </div>
                                        <div className="featured-heading">
                                            <div className='fh-img'></div>
                                            <div className='fh-text'>{item.title}</div>
                                            <div className='fh-sub-text'>{item.description}</div>
                                        </div>
                                    </Link>
                                ))}
                            </OwlCarousel>
                        </div>
                        <div className='fslideraside'>
                            <Squarebanner />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Featuredslider;