import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import helpers from './../../utils';

function Trendingwidgets() {
    const [data, setData] = useState([]);    

    useEffect(() => {   
        const fetchData = async () => {          
            const dataRes = await helpers.loadTrendingNews(7);
            if (dataRes != '') {
                setData(dataRes.news);
            }       
        };
        fetchData();      
    }, []);

    useEffect(() => {},[data]);

    return (
        <div className="trending-box">
            <div className="trending-lable">Trending</div>
            <ul className="trending-news-list">
                {Object.keys(data).length > 0 && (
                    data.map((item,index) => {
                        return (<li key={index}>
                            <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                <span className="trending-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" width="100" height="100">
                                        <path d="m80.1,405.6c3.5,2.4 17,9.1 28.4-5.3l77.2-112.8 81.7,78.8c4.3,4.1 10.2,6.3 16.2,5.6 5.9-0.6 11.3-3.7 14.8-8.6l135.5-194.3 4.9,65c1.2,16.5 16.7,19.2 21.9,18.8 11.2-0.9 19.7-10.6 18.8-21.9l-8.6-114.3c-0.8-11.2-10.6-19.7-21.9-18.8l-114.3,8.6c-11.2,0.8-19.7,10.6-18.8,21.9 0.8,11.2 10.5,19.6 21.9,18.8l65-4.9-124.2,178-81.9-79c-4.3-4.2-10.3-6.3-16.2-5.6-6,0.6-11.4,3.8-14.8,8.8l-90.9,132.8c-6.4,9.3-4,22 5.3,28.4z"></path>
                                    </svg>
                                </span>
                                {item.title}
                            </Link>
                        </li>
                    )})
                )}
            </ul>            
        </div>
    );
}

export default Trendingwidgets;