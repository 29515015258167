import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import * as $ from 'jquery';
import 'jquery-validation';
import helpers from './../utils';
import PropTypes from 'prop-types'


// Css Link
import '../css/suportform.css'

// Design Link
import Storelink from '../Components/Storelink'

function Contactus(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const [errMsg, setErrMsg] = useState([]);
    const [successMsg, setSuccessMsg] = useState([]);

    const formRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrMsg([]);
        setSuccessMsg([]);
        $.validator.addMethod("phone_exp", function(value, element) {
            return this.optional(element) || /^[0-9-+]+$/.test(value);
        }, "Please enter a valid number.");
        // Use jQuery validation on the form
        $(formRef.current).validate({
          rules: {
            name: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            mobile_no: {
                required: true,
                number: true,
                phone_exp: true,
            },
            messg: {
                required: true,
            },
          },
          messages: {
            name: {
                required: 'Name is required',
            },
            email: {
                required: 'Email is required',
                email: 'Invalid email address',
            },
            mobile_no: {
                required: 'Mobile Number is required',
                phone_exp: 'Only numbers and dash are allowed.',
            },
            messg: {
                required: 'Message is required',
            },
          },
          submitHandler: async function (form) {            
            setErrMsg([]);
            setSuccessMsg([]);
            const formData = $(formRef.current).serializeArray();
            var form_data = [];
            if(Object.keys(formData).length > 0)
            {
                formData.map((item,index) => (        
                    form_data[item.name] = item.value
                ));     
            }
            try {
                const related_news_response = await helpers.apiCallWithErrorMessage(helpers.API_BASE_URL+'send-support',form_data);
                if (related_news_response != '') {
                    if(related_news_response.error==0 || related_news_response.error == '0')
                    {
                        if (formRef.current) {                  
                            formRef.current.reset();
                        }
                        setSuccessMsg({'success' : related_news_response.msg});
                    }
                    else
                    {
                        setErrMsg({'error' : related_news_response.msg});
                    }
                }
                else
                {
                    setErrMsg({'error' : props.pagetitle+' not send'});
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
          },
        });

        // Trigger the form validation
        $(formRef.current).valid();
    };

    useEffect(() => {},[errMsg]);
    useEffect(() => {
        if(Object.keys(successMsg).length > 0)
        {
            setTimeout(function () {
                setSuccessMsg([]);
            }, 10000);
        }
    }, [successMsg]);

    return (
        <>       
            <Helmet>
                <title>Contact Us | Morbi Update</title>
                <meta property="og:description" content="Contact Us" />
                <meta property="og:title" content="Contact Us | Morbi Update" />
                <meta property="og:url" content={`${window.location.origin}`} />
                <meta name="twitter:title" content="Contact Us | Morbi Update" />
                <meta name="twitter:description" content="Contact Us" />
                <meta name="title" content="Contact Us | Morbi Update" />
                <meta name="description" content="Contact Us" />
                <meta name="keywords" content="Contact Us" />
            </Helmet>
            <div className='breadcrumb-outer'>
                <div className='custom-container'>
                    <div className='breadcrumb-bx'>
                        <Link className='breadcrumb-link breadcrumb-back' to="/">હોમ<i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        <Link className='breadcrumb-link breadcrumb-active' to="/">{props.pagetitle}</Link>
                    </div>
                </div>
            </div>

            <div className='custom-container'>
                <div className='page-heading'>{props.pagetitle}</div>
                <div className='contact-row'>
                    <div className='cr-item'>
                        {
                            (Object.keys(errMsg).length > 0) && (
                                <div class="alert alert-danger" role="alert">
                                    <h5 class="alert-heading">Error</h5>
                                    <hr />
                                    {
                                        Object.keys(errMsg).map((item:any,index:any) => (
                                            <p key={index} className="mb-0"  dangerouslySetInnerHTML={{ __html: errMsg[item] }}></p>
                                        ))  
                                    }                                  
                                </div>
                            )
                        }
                        {
                            (Object.keys(successMsg).length > 0) && (
                                <div class="alert alert-success" role="alert">
                                    <h5 class="alert-heading">Success</h5>
                                    <hr />
                                    {
                                        Object.keys(successMsg).map((item:any,index:any) => (
                                            <p key={index} className="mb-0"  dangerouslySetInnerHTML={{ __html: successMsg[item] }}></p>
                                        ))  
                                    }                                  
                                </div>                                
                            )
                        }
                        <form ref={formRef} onSubmit={handleSubmit} className="support-form">
                            <div className="custom-form__group">
                                <input type="text" name="name" placeholder=" "/>
                                <label className="group__label">Name</label>
                            </div>
                            <div className="custom-form__group">
                                <input type="email" name="email" placeholder=" "/>
                                <label className="group__label">Email</label>
                            </div>
                            <div className="custom-form__group">
                                <input type="number" name="mobile_no" placeholder=" "/>
                                <label className="group__label">Mobile No.</label>
                            </div>
                            <div className="custom-form__group">
                                <textarea name="messg" rows="3" placeholder=" "></textarea>
                                <label className="group__label">Message</label>
                            </div>

                            <button type="submit" className="support-form-btn">Submit</button>
                        </form>
                    </div>
                    <div className='cr-item'>
                        <div className="support-social">
                            <a className='ss-item mail-ss' href="mailto:morbiupdate@gmail.com"><span className='ss-icon'><ion-icon name="mail-open-outline"></ion-icon></span>morbiupdate@gmail.com</a>
                            <a className='ss-item call-ss' href="tel:+919537676276"><span className='ss-icon'><ion-icon name="call-outline"></ion-icon></span>+919537676276</a>
                            <a className='ss-item wh-ss'  rel="noreferrer" href="https://api.whatsapp.com/send?phone=+919537676276&text=Hello" target="_blank"><span className='ss-icon'><ion-icon name="logo-whatsapp"></ion-icon></span>+919537676276</a>
                            <a className='ss-item fb-ss'  rel="noreferrer" href="https://www.facebook.com/morbiupdate" target="_blank"><span className='ss-icon'><i className="fa fa-facebook"></i></span>Morbi Update</a>
                            {/* <a className='ss-item insta-ss'  rel="noreferrer" href="http://localhost:3000/" target="_blank"><span className='ss-icon'><i className="fa fa-instagram"></i></span>Morbi Update</a> */}
                            <a className='ss-item tw-ss'  rel="noreferrer" href="https://twitter.com/morbiupdate" target="_blank"><span className='ss-icon'><i className="fa fa-twitter"></i></span>Morbi Update</a>
                            <a className='ss-item yt-ss'  rel="noreferrer" href="https://www.youtube.com/channel/UCngqmj55wuJrWiNg5kyCKGw" target="_blank"><span className='ss-icon'><i className="fa fa-youtube-play"></i></span>Morbi Update</a>
                            <a className='ss-item cm-ss'  rel="noreferrer" href="https://goo.gl/maps/atjE1yVRR5a5PSEM8" target="_blank"><span className='ss-icon'><ion-icon name="location-outline"></ion-icon></span>Shantikunj Media Private Limited First floor, balaji complex, opp. k.k.steel, Near ram chok, Shanala road, Morbi – 363642</a>
                        </div>
                        <div className='common-store-links support-csl'>
                            <Storelink />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contactus;


Contactus.propTypes = {
    pagetitle: PropTypes.string.isRequired,
}

Contactus.defaultProps = {
    pagetitle: 'Contact us',
}