import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import helpers from './../utils';

// Images Common File
import Img from '../Components/Img';

// Design File
import Breakingnews from "../Components/Breakingnews";
import Featuredslider from "../Components/Featuredslider";
import Squarebanner from "../Components/Adsection/Squarebanner";
import Horizontalbanner from "../Components/Adsection/Horizontalbanner";
import Horizontalbigbanner from "../Components/Adsection/Horizontalbigbanner";
import Verticalbanner from "../Components/Adsection/Verticalbanner";
import Trendingwidgets from "../Components/Widgets/Trendingwidgets";
import Trendingimgwidgets from "../Components/Widgets/Trendingimgwidgets";

function Newscatbox() {

    const [componentCount, setComponentCount] = useState(null);

    const [showComponent1, setShowComponent1] = useState([]);
    const [showComponent2, setShowComponent2] = useState([]);
    const [showComponent3, setShowComponent3] = useState([]);
    const [showComponent4, setShowComponent4] = useState([]);
    const [showComponent5, setShowComponent5] = useState([]);
    
    const [category, setCategory] = useState([]);
    const [newsData, setNewsData] = useState([]);

    const [loading, setLoading] = useState(false);
    const sendRequestRef = useRef(true);

    useEffect(() => {
        setLoading(true);
        const categoryData = async () => {
            try {
                const nav_response = await helpers.apiCall(helpers.API_BASE_URL+'get-categories');
                if (nav_response != '') {
                    setCategory([...nav_response.catgories,...nav_response.sub_catgories]);
                    setComponentCount((prevComponentCount) => 1);
                    sendRequestRef.current = true;
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        categoryData();
    },[]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(sendRequestRef.current === true) {
                    sendRequestRef.current = false;
                    setLoading(true);
                    if(componentCount == 1)
                    {                    
                        var category_1 = ((category.length > 0) ? (category?.[componentCount-1]?.id) : '');
                        if(category_1 != '')
                        {
                            const response1 = await helpers.getCategoryNews(category_1, 7, 'Home');
                            if (response1 != '') {
                                setShowComponent1(response1);                        
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                        }
                        else
                        {
                            sendRequestRef.current = false;
                        }
                    }
                    if(componentCount == 2)
                    {
                        var category_2 = ((category.length > 0) ? (category?.[componentCount-1]?.id) : '');
                        if(category_2 != '')
                        {
                            const response2 = await helpers.getCategoryNews(category_2, 7, 'Home');
                            if (response2 != '') {
                                setShowComponent2(response2);                        
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                        }
                        else
                        {
                            sendRequestRef.current = false;
                        } 
                    }
                    if(componentCount == 3)
                    {
                        var category_3 = ((category.length > 0) ? (category?.[componentCount-1]?.id) : '');
                        if(category_3 != '')
                        {
                            const response3 = await helpers.getCategoryNews(category_3, 7, 'Home');
                            if (response3 != '') {
                                setShowComponent3(response3);                     
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                        }
                        else
                        {
                            sendRequestRef.current = false;
                        }
                    }
                    if(componentCount == 4)
                    {
                        var category_4 = ((category.length > 0) ? (category?.[componentCount-1]?.id) : '');
                        if(category_4 != '')
                        {
                            const response4 = await helpers.getCategoryNews(category_4, 7, 'Home');
                            if (response4 != '') {
                                setShowComponent4(response4);                        
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                        }
                        else
                        {
                            sendRequestRef.current = false;
                        }
                    }  
                    if(componentCount >= 5)
                    {
                        if(componentCount == 5)
                        {
                            setNewsData([]);
                        }
                        var category_5 = (((category.length > 0) && ((componentCount-1) in category)) ? (category?.[componentCount-1]?.id) : '');
                        if(category_5 != '')
                        {
                            var response5 = await helpers.getCategoryNews(category_5, 7, 'Home');
                            if (response5 != '') {                            
                                setNewsData((prevNewsData) => [...prevNewsData, response5]);
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                            else
                            {
                                setComponentCount((prevComponentCount) => prevComponentCount + 1);
                                sendRequestRef.current = true;
                            }
                        }
                        else
                        {
                            sendRequestRef.current = false;
                        }
                    }
                }             
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && !loading) {
                if(componentCount != '')
                {
                    fetchData();
                }
            }
        };
        
        if(componentCount == 1)
        {
            fetchData(); 
        }
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [loading, componentCount]);

    useEffect(() => {},[showComponent1, showComponent2, showComponent3, showComponent4]);
    useEffect(() => {
        // console.log("showComponent5=",showComponent5);
    },[showComponent5]);

    useEffect(() => {},[category]);

    useEffect(() => {},[newsData]);

    const renderNews = (itemdata,index) => {        
        return (
            <div key={index}>
                <section className='newscatmainbox'>
                    <div className='newssidebx'>     
                        <Verticalbanner />
                    </div>
                    <div className="newscatbox">
                        <h6 className="newscatbox-lable">{itemdata?.['category']}</h6>
                        
                        <div className='cat-main-news'>
                            <Link to={((itemdata?.['news']?.[0]?.['news_link2']) ? itemdata?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} className="cmn-img">
                                <img className="cmn-img-bg" src={itemdata?.['news']?.[0]?.['image']} alt="" loading="lazy" />
                            </Link>
                            <Link className="cmn-item-text" to={((itemdata?.['news']?.[0]?.['news_link2']) ? itemdata?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )}>
                                <div className="cmn-item-text-hed">{((itemdata?.['news'] && itemdata?.['news']?.length > 0) ? (itemdata?.['news']?.[0]?.['highlight_title']+' '+itemdata?.['news']?.[0]?.['title']) : '')}</div>
                                <div className="cmn-item-text-sub">{itemdata?.['news']?.[0]?.['sort_desc']}</div>
                            </Link>
                        </div>
                        {
                            itemdata?.['news'] && (itemdata?.['news']?.length > 1) && (
                                <div className="cat-news-list">
                                    {
                                        itemdata?.['news'].map((item,index) => {
                                            if(index != 0)
                                            {
                                                return (<div className="cnl-item" key={index}>
                                                    <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                        <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                                    </Link>
                                                    <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                        <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                    </Link>
                                                </div>)
                                            }
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                </section>

                <Horizontalbanner />
            </div>
        );
    }

    return (
        <>
            <Breakingnews />
            <Featuredslider />            
            <div className='custom-container'>
                <div className="newscatbox-outer">
                    <Horizontalbanner />
                    {
                        componentCount >= 1 && (Object.keys(showComponent1).length > 0) && (
                            <>
                                <section className='newscatmainbox'>
                                    <div className='newssidebx'>
                                        <Trendingwidgets />
                                        <Horizontalbigbanner />
                                    </div>
                                    <div className="newscatbox">
                                        <h6 className="newscatbox-lable">{showComponent1?.['category']}</h6>
                                        
                                        <div className='cat-main-news'>
                                            <Link to={((showComponent1?.['news']?.[0]?.['news_link2']) ? showComponent1?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} className="cmn-img">
                                                <img className="cmn-img-bg" src={showComponent1?.['news']?.[0]?.['image']} alt="" loading="lazy" />
                                            </Link>
                                            <Link className="cmn-item-text" to={((showComponent1?.['news']?.[0]?.['news_link2']) ? showComponent1?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} >
                                                <div className="cmn-item-text-hed">{((showComponent1?.['news'] && showComponent1?.['news']?.length > 0) ? (showComponent1?.['news']?.[0]?.['highlight_title']+' '+showComponent1?.['news']?.[0]?.['title']) : '')}</div>
                                                <div className="cmn-item-text-sub">{showComponent1?.['news']?.[0]?.['sort_desc']}</div>
                                            </Link>
                                        </div>
                                        {
                                            showComponent1?.['news'] && (showComponent1?.['news']?.length > 1) && (
                                                <div className="cat-news-list">
                                                    {
                                                        showComponent1?.['news'].map((item,index) => {
                                                            if(index != 0)
                                                            {
                                                                return (<div className="cnl-item" key={index}>
                                                                    <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                                        <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                                                    </Link>
                                                                    <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                                        <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                                    </Link>
                                                                </div>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </section>

                                <Horizontalbanner />
                            </>
                        )
                    }
                    {
                        componentCount >= 2 && (Object.keys(showComponent2).length > 0) && (
                            <>
                                <section className='newscatmainbox'>
                                    <div className='newssidebx'>     
                                        <Squarebanner />
                                        <Squarebanner />
                                    </div>
                                    <div className="newscatbox">
                                        <h6 className="newscatbox-lable">{showComponent2?.['category']}</h6>
                                        
                                        <div className='cat-main-news'>
                                            <Link to={((showComponent2?.['news']?.[0]?.['news_link2']) ? showComponent2?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} className="cmn-img">
                                                <img className="cmn-img-bg" src={showComponent2?.['news']?.[0]?.['image']} alt="" loading="lazy" />
                                            </Link>
                                            <Link className="cmn-item-text" to={((showComponent2?.['news']?.[0]?.['news_link2']) ? showComponent2?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )}>
                                                <div className="cmn-item-text-hed">{((showComponent2?.['news'] && showComponent2?.['news']?.length > 0) ? (showComponent2?.['news']?.[0]?.['highlight_title']+' '+showComponent2?.['news']?.[0]?.['title']) : '')}</div>
                                                <div className="cmn-item-text-sub">{showComponent2?.['news']?.[0]?.['sort_desc']}</div>
                                            </Link>
                                        </div>
                                        {
                                            showComponent2?.['news'] && (showComponent2?.['news']?.length > 1) && (
                                                <div className="cat-news-list">
                                                    {
                                                        showComponent2?.['news'].map((item,index) => {
                                                            if(index != 0)
                                                            {
                                                                return (<div className="cnl-item" key={index}>
                                                                    <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                                        <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                                                    </Link>
                                                                    <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                                        <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                                    </Link>
                                                                </div>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </section>

                                <Horizontalbanner />
                            </>
                        )
                    }
                    {
                        componentCount >= 3 && (Object.keys(showComponent3).length > 0) && (
                            <>
                                <section className='newscatmainbox'>
                                    <div className='newssidebx'>
                                        <Trendingimgwidgets />   
                                        <Horizontalbigbanner />
                                    </div>
                                    <div className="newscatbox">
                                        <h6 className="newscatbox-lable">{showComponent3?.['category']}</h6>
                                        
                                        <div className='cat-main-news'>
                                            <Link to={((showComponent3?.['news']?.[0]?.['news_link2']) ? showComponent3?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} className="cmn-img">
                                                <img className="cmn-img-bg" src={showComponent3?.['news']?.[0]?.['image']} alt="" loading="lazy" />
                                            </Link>
                                            <Link className="cmn-item-text" to={((showComponent3?.['news']?.[0]?.['news_link2']) ? showComponent3?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )}>
                                                <div className="cmn-item-text-hed">{((showComponent3?.['news'] && showComponent3?.['news']?.length > 0) ? (showComponent3?.['news']?.[0]?.['highlight_title']+' '+showComponent3?.['news']?.[0]?.['title']) : '')}</div>
                                                <div className="cmn-item-text-sub">{showComponent3?.['news']?.[0]?.['sort_desc']}</div>
                                            </Link>
                                        </div>
                                        {
                                            showComponent3?.['news'] && (showComponent3?.['news']?.length > 1) && (
                                                <div className="cat-news-list">
                                                    {
                                                        showComponent3?.['news'].map((item,index) => {
                                                            if(index != 0)
                                                            {
                                                                return (<div className="cnl-item" key={index}>
                                                                    <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                                        <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                                                    </Link>
                                                                    <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                                        <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                                    </Link>
                                                                </div>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </section>

                                <Horizontalbanner />
                            </>
                        )
                    }
                    {
                        componentCount >= 4 && (Object.keys(showComponent4).length > 0) && (
                            <>
                                <section className='newscatmainbox'>
                                    <div className='newssidebx'>     
                                        <Verticalbanner />
                                    </div>
                                    <div className="newscatbox">
                                        <h6 className="newscatbox-lable">{showComponent4?.['category']}</h6>
                                        
                                        <div className='cat-main-news'>
                                            <Link to={((showComponent4?.['news']?.[0]?.['news_link2']) ? showComponent4?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )} className="cmn-img">
                                                <img className="cmn-img-bg" src={showComponent4?.['news']?.[0]?.['image']} alt="" loading="lazy" />
                                            </Link>
                                            <Link className="cmn-item-text" to={((showComponent4?.['news']?.[0]?.['news_link2']) ? showComponent4?.['news']?.[0]?.['news_link2'] : 'javascript:void(0);' )}>
                                                <div className="cmn-item-text-hed">{((showComponent4?.['news'] && showComponent4?.['news']?.length > 0) ? (showComponent4?.['news']?.[0]?.['highlight_title']+' '+showComponent4?.['news']?.[0]?.['title']) : '')}</div>
                                                <div className="cmn-item-text-sub">{showComponent4?.['news']?.[0]?.['sort_desc']}</div>
                                            </Link>
                                        </div>
                                        {
                                            showComponent4?.['news'] && (showComponent4?.['news']?.length > 1) && (
                                                <div className="cat-news-list">
                                                    {
                                                        showComponent4?.['news'].map((item,index) => {
                                                            if(index != 0)
                                                            {
                                                                return (<div className="cnl-item" key={index}>
                                                                    <Link to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )} className="cnl-item-img">
                                                                        <img className="cnl-item-img-bg" src={item.image_sm} alt="" loading="lazy" />
                                                                    </Link>
                                                                    <Link className="cnl-item-text" to={((item.news_link2) ? item.news_link2 : 'javascript:void(0);' )}>
                                                                        <div className="cnl-item-text-hed">{item.highlight_title+' '+item.title}</div>
                                                                    </Link>
                                                                </div>)
                                                            }
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </section>

                                <Horizontalbanner />
                            </>
                        )
                    }

                    {
                        newsData.map(renderNews)
                    }

                    { loading && (
                            <section className='newscatmainbox skeletonmainbox'>
                                <div className='newssidebx'>     
                                   <div className='loader-bx loader-design'></div>
                                </div>
                                <div className="newscatbox">
                                    <div className="newscatbox-lable loader-design ld2"></div>
                                    
                                    <div className='cat-main-news'>
                                        <div className="cmn-img"></div>
                                        <div className="cmn-item-text">
                                            <div className="cmn-item-text-hed">
                                                <div className="loader-design ld1"></div>
                                                <div className="loader-design ld1"></div>
                                                <div className="loader-design ld1"></div>
                                            </div>
                                            <div className="cmn-item-text-sub">
                                                <div className="loader-design ld3"></div>
                                                <div className="loader-design ld3"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cat-news-list">
                                        
                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cnl-item">
                                            <div className="cnl-item-img"></div>
                                            <div className="cnl-item-text">
                                                <div className="cnl-item-text-hed">
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                    <div className="loader-design ld3"></div>
                                                </div>
                                            </div>
                                        </div>

                                        

                                    </div>
                                </div>
                            </section>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default Newscatbox;